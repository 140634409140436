import React from "react";
import { Banner } from "../components/Banner";
import { BannerTwo } from "../components/BannerTwo";
import { BlogCard } from "../components/BlogCard";

const Home = () => {
  return (
    <div>
      <BannerTwo />
      {/* <Banner /> */}
    </div>
  );
};

export default Home;
