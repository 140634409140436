import React from "react";
import { Testimonials } from "../components/Testimonials";

const TestimonialsPage = () => {
  return (
    <div>
      {/* <Testimonials /> */}
      <Testimonials />
    </div>
  );
};

export default TestimonialsPage;
